import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Hem: "/",
  // Blogg: "/blogg",
  Imprint: "en/imprint"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/imprint"
);

const Imprint = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Imprint"
        description="Information om operatören av webbplatsen samt juridiska meddelanden."
        lang="se"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article={false}>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Imprint</H>
        <p>Operatör av webbplatsen bluerivermountains.com:</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 Köpenhamn
          <br />
          Danmark
        </p>
        <p className="baseline">
          E-post: <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Telefon: +45 93 86 85 64
        </p>
        <p className="baseline">Skatteidentifikationsnummer: 39474263</p>
        <p>Denna sida är upphovsrättsskyddad av Matthias Kupperschmidt.</p>
        <H as="h2">Juridisk information</H>
        <p>
          Jag granskar regelbundet informationen på denna webbplats. Trots all omsorg kan uppgifterna ha ändrats under tiden. Ett ansvar eller garanti för aktualitet, korrekthet och fullständighet av informationen kan därför inte accepteras. Detsamma gäller för webbplatser som hänvisas till via hyperlänkar. Matthias Kupperschmidt ansvarar inte för innehållet på de webbplatser som nås som ett resultat av en sådan koppling. Reproduktion av information eller data – särskilt användning av texter, delar av texter eller bilder – kräver föregående samtycke. Detta gäller även alla blogginlägg. Jag påpekar att jag lagrar all data som matas in i sökformuläret och inte använder dessa uppgifter för egen reklam, inte heller vidarebefordrar dem till tredje part.
        </p>
        <p>
          Ytterligare information finns i min <Link to="/se/integritetspolicy">integritetspolicy</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Imprint;
